import { FIELD_OFFICER_ROLE_ENUM } from "./StringConstant"

export const RoutesConstants = {
  FIELD_OFFICER: {
    ASSIGN_MITRA: () => `/field-officer/assign/mitra`,
    EDIT_FO: (id) => `/field-officer/edit/${id}`,
    CREATE_FO: () => `/field-officer/new`,
    LIST_FO_AGRONOMIS: (tab) =>
      `/field-officer/${FIELD_OFFICER_ROLE_ENUM.FIELD_OFFICER_AGRONOMIST}/${tab}`,
    DETAIL_FO_AGRONOMIS: (role, id) => `/field-officer/${role}/list-fo/${id}`,
    LIST_FO: (role) => `/field-officer/${role}`,
    LIST_FO_QA: (tab) => `/field-officer/${FIELD_OFFICER_ROLE_ENUM.FIELD_OFFICER_QA}/${tab}/list`,
    DETAIL_FO_QA: (role, tab, id) => `/field-officer/${role}/${tab}/list/detail/${id}`,
    DETAIL_FO: (role, id) => `/field-officer/${role}/detail/${id}`,
    EDIT_FO_QA: (role, tab, id) => `/field-officer/${role}/${tab}/edit/${id}`,
    EDIT_TEAM_DRONE: (action, id) =>
      `/field-officer/${FIELD_OFFICER_ROLE_ENUM.FIELD_OFFICER_AGRONOMIST}/team/${action}/${id}`,
    CREATE_TEAM: (action) =>
      `/field-officer/${FIELD_OFFICER_ROLE_ENUM.FIELD_OFFICER_AGRONOMIST}/team/${action}`,
    DETAIL_FO_AGRONOMIST_TEAM: (id) => `/field-officer/agronomist/team/detail/${id}`
  },
  ORDER: {
    LIST_PASCA_PLANTING_BY_STATUS: (status) => `/order/pasca-planting/${status}`,
    LIST_PRE_PLANTING_BY_STATUS: (status) => `/order/pre-planting/${status}`,
    DETAIL_PASCA_PLANTING: (status, id) => `/order/pasca-planting/${status}/${id}`,
    DETAIL_PRE_PLANTING: (status, id) => `/order/pre-planting/${status}/${id}`,
    LIST_BY_STATUS: (status) => `/order/list/${status}`,
    LIST_PENDING_BY_TAB: (tab) => `/order/list/pending/${tab}`,
    DETAIL: (status, id) => `/order/detail/${status}/${id}`,
    APPROVAL: (status, id) => `/order/approval/${status}/${id}`,
    APPROVAL_PASCA_TANAM: (status, id) => `/order/pasca-planting/approval/${status}/${id}`,
    APPROVAL_PRE_PLANTING: (status, id) => `/order/pre-planting/approval/${status}/${id}`,
    APPROVAL_BULK: (planting) => `/order/pending/approval/bulk/${planting}`,
    APPROVAL_BULK_PASCA_TANAM: () => `/order/pasca-planting/approve/bulk`,
    APPROVAL_BULK_PRE_PLANTING: () => `/order/pre-planting/approve/bulk`
  },
  FARMER: {
    LIST_FARMER_TERVERIFIKASI: (tab) => `/farmer/terverifikasi/${tab}`,
    DETAIL_FARMER: (status, id) => `/farmer/list/${status}/${id}`,
    LIST_FARMER_CA: () => `/farmer/list-ca/list`,
    LIST_FARMER: (tab) => `/farmer/list/${tab}`,
    CREATE: () => `/farmer/action/new`
  },
  MITRA: {
    DETAIL_MITRA: (status, id) => `/mitras/list/${status}/${id}`,
    LIST_MITRA: (status) => `/mitras/list/${status}`,
    CREATE: () => `/mitras/action/new`,
    LIST_MITRA_KUR: () => `/mitras/list-kur/list`
  },
  AREA: {
    LIST_AREA_TEREGISTRASI: (tab) => `/lahan/teregistrasi/${tab}`,
    DETAIL_AREA: (status, id) => `/lahan/list/${status}/${id}`,
    LIST_AREA_ACTIVITY: (tab) => `/lahan/activity-list/${tab}`,
    LIST_AREA_ACTIVITY_KUR: () => `/lahan/activity-kur`,
    LIST_SO: (tab) => `/lahan/sales-order/${tab}`,
    DETAIL_SO: (id) => `/lahan/so/detail/${id}`,
    DETAIL_SPK: (id) => `/lahan/spk/detail/${id}`,
    FORM_FAILED_HARVEST: (id) => `/lahan/failed-harvest/${id}`,
    DETAIL_ACTIVITY: (areaId, activity, activityId) =>
      `/lahan/active/${areaId}/${activity}/${activityId}`,
    PREVIEW_BULK_ERP: () => `/lahan/activity-list/bulk/preview`,
    DETAIL_AREA_PENDING_UNASSIGN: (status, id) => `/lahan/list/${status}/${id}/pending-unassigned`,
    DETAIL_HISTORY_ACTIVITY: (status, areaId, id) =>
      `/lahan/list/${status}/${areaId}/history-activity/${id}`,
    DETAIL_ACTIVITY_SEEDLING: (status, id, activityId) =>
      `/lahan/${status}/${id}/seedling/${activityId}`,
    DETAIL_ACTIVITY_TILLAGE: (status, id, activityId) =>
      `/lahan/${status}/${id}/tillage/${activityId}`,
    DETAIL_ACTIVITY_PLANTING: (status, id, activityId) =>
      `/lahan/${status}/${id}/planting/${activityId}`,
    DETAIL_AREA_LEAD: (id) => `/lahan/list/lead/${id}`,
    DETAIL_AREA_UNASSIGNED: (status, id) => `/lahan/list-unassign/${status}/${id}`,
    LIST_AREA_READY_PLANTING_SEASON_CONFIRMATION: () => "/lahan/ready-planting-season-confirmation"
  },
  MAC: {
    LIST_PROFILE: () => `/mac/profile`,
    PROFILE_DETAIL: (id) => `/mac/profile/${id}`,
    LIST_SPRAYING: (tab) => `/mac/spraying/${tab}`,
    LIST_USER: () => `/mac/user`,
    USER_DETAIL: (id) => `/mac/user/${id}`,
    ACTIVITY_REPORT_LIST: () => `/mac/activity-report`,
    DETAIL_ACTIVITY_REPORT: (id) => `/mac/activity-report/detail/${id}`,
    SPRAYING_DETAIL: (status, id) => `/mac/spraying/${status}/${id}`,
    MAC_SPRAYING_APPROVAL: (status, id) => `/mac/spraying/approval/${status}/${id}`
  },
  MASTER_DATA: {
    REGIONAL: {
      PAGE: () => "/master/regional/list",
      ADD_REGIONAL: () => "/master/regional/new",
      CREATE_SUB_REGIONAL: (id) => `/master/sub-regional/created/${id}`,
      EDIT_SUB_REGIONAL: (id, regionalId) => `/master/sub-regional/edit/${regionalId}/${id}`,
      DETAIL_REGIONAL: (id) => `/master/regional-detail/${id}`,
      EDIT_REGIONAL: (id) => `/master/regional/edit/${id}`
    },
    PACKAGE: {
      PAGE: () => `/master/package`,
      DETAIL: (id) => `/master/package/list/detail/${id}`,
      EDIT: (id) => "/master/package/list/edit/" + id
    },
    TREATMENT: {
      NEW: (package_id, plant_type_id, sequence) =>
        `/master/treatment/new/${package_id}/${plant_type_id}/${sequence}`,
      EDIT: (package_id, plant_type_id, sequence, treatment_id) =>
        `/master/treatment/edit/${package_id}/${plant_type_id}/${sequence}/${treatment_id}`
    }
  },
  SETTING: {
    CREATE_USER: () => `/account/action/new`,
    LIST_ACCOUNT: () => `/account/list`,
    EDIT_USER: (id) => `/account/action/edit/${id}`
  },
  SUBMISSION: {
    CREATE_SUBMISSION: () => `/submission/create`,
    LIST_SUBMISSION_BY_STATUS: (status) => `/submission/certification-block/${status}`,
    DETAIL_AREA_CERTIFICATION_PENDING: (id) => `/submission/certification-block/pending/${id}`,
    DETAIL_BLOCK_CERTIFICATION: (status, id) => `/submission/certification-block/${status}/${id}`
  }
}
